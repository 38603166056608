import { css } from '@emotion/react'

import { Switch as ReactAriaSwitch, SwitchProps } from 'react-aria-components'
import { mTheme } from '../style/themes'

let style = css({
  display: 'flex',
  alignItems: 'center',
  gap: mTheme.size.XXS,
  fontSize: mTheme.fontSize.M,
  color: mTheme.fontColor.plain.primary,
  forcedColorAdjust: 'none',

  '& .indicator': {
    width: '2.1rem',
    height: '1.25rem',
    border: `2px solid ${mTheme.borderColor.secondary}`,
    background: mTheme.backgroundColor.primary,
    borderRadius: 16,
    transition: 'all 200ms',

    '&:before': {
      content: '""',
      display: 'block',
      margin: '0.1rem',
      width: mTheme.size.S,
      height: mTheme.size.S,
      background: mTheme.color.primary,
      borderRadius: '16px',
      transition: 'all 200ms cubic-bezier(0.47, 1.6, 0.41, 0.8)',
    },
  },

  '.label': {
    color: mTheme.fontColor.plain.secondary,
  },

  '&[data-pressed] .indicator': {
    borderColor: mTheme.color.primary,

    '&:before': {
      background: mTheme.fontColor.plain.secondary,
    },
  },

  '&[data-selected]': {
    '.label': {
      color: mTheme.fontColor.plain.primary,
    },
    '.indicator': {
      borderColor: mTheme.borderColor.primary,
      background: mTheme.color.primary,

      '&:before': {
        background: mTheme.fontColor.plain.primary,
        transform: 'translateX(105%)',
      },
    },

    '&[data-pressed]': {
      '.indicator': {
        borderColor: 'pink',
        background: mTheme.backgroundColor.secondary,
      },
    },
  },

  '&[data-focus-visible] .indicator': {
    outline: `2px solid ${mTheme.color.focusRing}`,
    outlineOffset: '2px',
  },
})

export function Switch({ children, ...props }: SwitchProps) {
  return (
    <ReactAriaSwitch {...props} css={style}>
      <div className="indicator" />{' '}
      <label className="label">
        <small>{children as React.ReactNode}</small>
      </label>
    </ReactAriaSwitch>
  )
}
