import { ButtonAsLink } from '../../components/Button'
import { useOnboardingSteps } from './OnboardingPage'

function OnboardingPrompt() {
  const { allCompleted } = useOnboardingSteps()

  if (allCompleted) {
    return null
  }

  return (
    <ButtonAsLink to="/onboarding" primary>
      Get started
    </ButtonAsLink>
  )
}

export default OnboardingPrompt
