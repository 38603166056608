import { Button, Dialog, DialogTrigger, Popover } from 'react-aria-components'
import { mTheme } from '../../style/themes'
import { css } from '@emotion/react'
import { Switch } from '../../components/Switch'
import { useEventFilter } from './EventFilterContext'

let styles = {
  container: css({
    display: 'flex',
    color: mTheme.fontColor.plain.primary,
    gap: mTheme.size.S,
  }),
  popoverButton: css({
    forcedColorAdjust: 'none',
    borderRadius: mTheme.radius.primary,
    border: 'none',
    padding: mTheme.size.S,
    fontSize: mTheme.fontSize.S,
    boxSizing: 'content-box',
    alignSelf: 'right',

    '&[data-pressed]': {
      boxShadow: 'none',
      background: mTheme.backgroundColor.primary,
      color: mTheme.fontColor.plain.tertiary,
    },

    // '&[data-focus-visible]': {
    //   outline: `2px solid ${mTheme.color.focusRing}`,
    //   outlineOffset: 2,
    // },
  }),

  popover: css({
    maxWidth: 'unset',
    background: mTheme.backgroundColor.primary,
    padding: mTheme.size.S,
    border: `2px solid ${mTheme.borderColor.secondary}`,
    borderRadius: mTheme.radius.primary,
    boxShadow: `${mTheme.backgroundColor.primary} 0px 8px 24px`,
  }),

  arrow: css({
    fontSize: mTheme.fontSize.XS,
    color: mTheme.fontColor.plain.tertiary,
    marginBottom: mTheme.size.XXS,
  }),
  dialog: css({
    display: 'flex',
    flexDirection: 'column',
    gap: mTheme.size.XS,
  }),
  hasChanged: css({
    color: mTheme.color.primary,
  }),
}

export function EventFilterButton() {
  let { filter, hasChanged, setFilter } = useEventFilter()

  return (
    <DialogTrigger css={styles.container}>
      <Button css={styles.popoverButton}>
        {hasChanged ? (
          <i className="fas fa-pencil" css={styles.hasChanged} />
        ) : (
          <i className="fas fa-sliders-h" />
        )}{' '}
        <span css={hasChanged && styles.hasChanged}>Filter</span>
      </Button>
      <Popover
        css={styles.popover}
        placement="bottom"
        offset={-5}
        crossOffset={35}
      >
        <Dialog css={styles.dialog}>
          <Switch
            isSelected={Boolean(filter.completed)}
            onChange={(isSelected) => {
              setFilter({ ...filter, completed: isSelected })
            }}
          >
            Past events
          </Switch>
        </Dialog>
      </Popover>
    </DialogTrigger>
  )
}
